import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AthleteClient,
  InsuranceGetResponse,
  InsuranceUpsertForPersonCommand,
  AzureStorageAccessInsuranceBlobDeleteCommand,
} from '@common/services/co-api-client';
import { InsuranceRecordDto } from '@common/services/co-api-client';
import { download } from '@common/helpers/download-file-helper';
import { BlobStorageService } from '@common/shared/services/blob-storage.service';
import { BlockBlobClient } from '@azure/storage-blob';
import { Router } from '@angular/router';
import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';
import { DateTimeService } from '@common/services/date-time.service';
import { ConfirmChangesDialogComponent } from '@common/shared/components/confirm-changes-dialog/confirm-changes-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medical-coverage',
  templateUrl: './medical-coverage.component.html',
  styleUrls: ['./medical-coverage.component.scss'],
  providers: [BlobStorageService],
})
export class MedicalCoverageComponent implements OnInit {
  @Output() savingChanges: EventEmitter<any> = new EventEmitter();
  @Output() savedChanges: EventEmitter<any> = new EventEmitter();
  @Input() otherSettingsChanged: boolean = false;

  public readonly maxYears: number = 16;
  public insuranceResponsePromise: Promise<void | InsuranceGetResponse>;
  displayCancelButton: boolean = false;
  medicalCoverageForm: FormGroup;
  insurance: InsuranceRecordDto;
  insuranceCast: string;
  dataWasChanged: boolean = false;
  loading: boolean = false;
  blobNameFormControl = new FormControl('');
  today = new Date();
  private _file: File;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private _formBuilder: FormBuilder,
    private athleteClient: AthleteClient,
    private blobStorageService: BlobStorageService,
    private router: Router,
    private splNavigationService: SplNavigationService,
    private dateTimeService: DateTimeService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) {
    this.medicalCoverageForm = new FormGroup({
      carrier: new FormControl('', Validators.required),
      insuredName: new FormControl('', Validators.required),
      memberId: new FormControl('', Validators.required),
      groupNumber: new FormControl('', Validators.required),
      insuredDateOfBirth: new FormControl('', [Validators.required]),
    });

    this.medicalCoverageForm.valueChanges.subscribe(() => {
      this.checkInsuranceChanges();
    });

    if (this.router.getCurrentNavigation()?.extras?.state?.cancel) {
      this.displayCancelButton = true;
    }
  }

  ngOnInit(): void {
    this.insuranceResponsePromise = this.getInsurance();
  }

  private getInsurance(): Promise<void | InsuranceGetResponse> {
    return this.athleteClient
      .getInsuranceForCurrentPerson()
      .toPromise()
      .then((res) => {
        this.insurance = res.insurance;
        this.fillInsuranceForm(res.insurance);
      });
  }

  private fillInsuranceForm(insurance: InsuranceRecordDto): void {
    if (!insurance) return;
    this.medicalCoverageForm.get('carrier').setValue(insurance.carrier);
    this.medicalCoverageForm.get('insuredName').setValue(insurance.insuredName);
    this.medicalCoverageForm.get('memberId').setValue(insurance.memberId);
    this.medicalCoverageForm.get('groupNumber').setValue(insurance.groupNumber);
    this.medicalCoverageForm
      .get('insuredDateOfBirth')
      .setValue(
        this.dateTimeService.getDateWithAddedTimeZone(
          insurance.insuredDateOfBirth,
        ),
      );
    this.blobNameFormControl.setValue(insurance.blobName);

    this.insuranceCast = JSON.stringify(this.medicalCoverageForm.getRawValue());
    this.checkInsuranceChanges();
  }

  public async openInsurance(): Promise<void> {
    if (this._file) {
      download(window.URL.createObjectURL(this._file), true);
    } else {
      const uri = (
        await this.athleteClient
          .getInsuranceBlobReadSasUri(
            this.insurance?.id,
            this.blobNameFormControl.value,
          )
          .toPromise()
      ).uri;
      const blockBlobClient: BlockBlobClient =
        await this.blobStorageService.getBlockBlobClient(uri);
      download(blockBlobClient.url);
    }
  }

  public async fileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      const name = `${uuid.v4()}-${file.name}`;
      this._file = file;
      const uri = (
        await this.athleteClient
          .getInsuranceBlobWriteSasUri(this.insurance?.id, name)
          .toPromise()
      ).uri;
      await this.blobStorageService.uploadData(file, uri);
      this.blobNameFormControl.setValue(name);
      if (this.insurance?.id) {
        this.updateInsuranceImageLink(name);
      }
    } else {
      this.blobNameFormControl.setValue('');
    }
  }

  private updateInsuranceImageLink(blobName: string): void {
    const body = this.medicalCoverageForm.getRawValue();
    body.id = this.insurance.id;
    body.blobName = blobName;
    const command: InsuranceUpsertForPersonCommand =
      new InsuranceUpsertForPersonCommand({
        insurance: new InsuranceRecordDto(body),
      });
    this.loading = true;
    if (command.insurance?.insuredDateOfBirth) {
      command.insurance.insuredDateOfBirth =
        this.dateTimeService.getDateWithoutTimeZone(
          new Date(command.insurance.insuredDateOfBirth),
        );
    }
    this.athleteClient.upsertInsuranceForPerson(command).subscribe(() => {
      this.loading = false;
    });
  }

  public async removeInsuranceLink(): Promise<void> {
    const blobName = this.blobNameFormControl.value;
    if (this._file) {
      this._file = null;
      this.blobNameFormControl.setValue('');
    }
    if (!this.insurance?.id) return;

    const translations: any = this.translateService.instant([
      'COMMON.ACTIONS.REMOVE',
      'PREFERENCES.REMOVE_INSURANCE_CARD',
    ]);

    const confirmRemoveInsuranceLink = this.dialog.open(
      ConfirmChangesDialogComponent,
      {
        data: {
          message: translations['PREFERENCES.REMOVE_INSURANCE_CARD'],
          continueButtonText: translations['COMMON.ACTIONS.REMOVE'],
        },
      },
    );
    const result = await confirmRemoveInsuranceLink.afterClosed().toPromise();
    if (!result) return;
    const deleteCommand = new AzureStorageAccessInsuranceBlobDeleteCommand({
      insuranceId: this.insurance?.id,
      blobName: blobName,
    });
    await this.athleteClient
      .deleteInsuranceBlob(deleteCommand)
      .toPromise()
      .then(() => {
        this.blobNameFormControl.setValue('');
        this.save();
      });
  }

  public save(): void {
    this.savingChanges.emit();
    const body = this.medicalCoverageForm.getRawValue();
    if (this.insurance?.id) {
      body.id = this.insurance.id;
    }
    body.blobName = this.blobNameFormControl.value;
    const command: InsuranceUpsertForPersonCommand =
      new InsuranceUpsertForPersonCommand({
        insurance: new InsuranceRecordDto(body),
      });
    this.loading = true;
    if (command.insurance?.insuredDateOfBirth) {
      command.insurance.insuredDateOfBirth =
        this.dateTimeService.getDateWithoutTimeZone(
          new Date(command.insurance.insuredDateOfBirth),
        );
    }
    this.athleteClient
      .upsertInsuranceForPerson(command)
      .subscribe((response) => {
        this.loading = false;
        if (response.result?.success) {
          this.insurance = response.result.data;
          this.savedChanges.emit();
        }
        this.insuranceCast = JSON.stringify(
          this.medicalCoverageForm.getRawValue(),
        );
        this.checkInsuranceChanges();
      });
  }

  private checkInsuranceChanges(): void {
    this.dataWasChanged =
      this.insuranceCast !==
      JSON.stringify(this.medicalCoverageForm.getRawValue());
  }

  public cancel(): void {
    this.splNavigationService.goBack();
  }
}
