import { environment } from '@common/co/environment';
import { IAppConfig } from '@common/co/core/config/app.config';

export const appConfig: IAppConfig = {
  apiUrl: environment.apiUrl,
  identityServerUrl: environment.identityServerUrl,
  isProduction: environment.production,
  isHmr: environment.hmr,
  googleClientId:
    '797971232132-4c7a6on5vnm4hranrbn8lhbpl5he8hr0.apps.googleusercontent.com',
  facebookAppId: '409467027576723',
  documentsSAConnectionString: environment.documentsSAConnectionString,
  appInsights: {
    instrumentationKey: environment.appInsights.instrumentationKey,
  },
  version: '0.15.0.6',
  clientUrl: 'https://thera.splzone.com',
  signalrHubUrl: 'hubs/co',
  bitmovinPlayerKey: environment.bitmovinPlayerKey,
};
