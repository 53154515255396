<spl-skeleton [showSkeleton]="insuranceResponsePromise" class="medical-coverage-wrapper" [formGroup]="medicalCoverageForm">
  <ng-container>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PREFERENCES.CARRIER' | translate }}</mat-label>     
      <input matInput formControlName="carrier"/>
      <mat-error *ngIf="medicalCoverageForm.get('carrier').hasError('required')">
        {{ 'PREFERENCES.CARRIER_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PREFERENCES.INSURED_NAME' | translate }}</mat-label>
      <input matInput formControlName="insuredName"/>
      <mat-error *ngIf="medicalCoverageForm.get('insuredName').hasError('required')">
        {{ 'PREFERENCES.INSURED_NAME_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PREFERENCES.MEMBER_ID' | translate }}</mat-label>
      <input matInput formControlName="memberId"/>
      <mat-error *ngIf="medicalCoverageForm.get('memberId').hasError('required')">
        {{ 'PREFERENCES.MEMBER_ID_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PREFERENCES.GROUP_NUMBER' | translate }}</mat-label>
      <input matInput formControlName="groupNumber"/>
      <mat-error *ngIf="medicalCoverageForm.get('groupNumber').hasError('required')">
        {{ 'PREFERENCES.GROUP_NUMBER_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PREFERENCES.INSURED_DATE_OF_BIRTH' | translate }}</mat-label>
      <input matInput formControlName="insuredDateOfBirth" [matDatepicker]="datePicker" [max]="today"/>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-error *ngIf="medicalCoverageForm.get('insuredDateOfBirth').hasError('required')">
        {{ 'PREFERENCES.INSURED_DATE_OF_BIRTH_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="insurance-card-wrapper" *ngIf="blobNameFormControl.value">
      <div class="insurance-link" (click)="openInsurance()">
        <mat-icon class="s-28">attach_file</mat-icon>
        <span>{{ 'PREFERENCES.INSURANCE_CARD' | translate }}</span>
      </div>
      <mat-icon class="s-28 remove-button" (click)="removeInsuranceLink()">clear</mat-icon>
    </div>
    <files-uploader
      *ngIf="!blobNameFormControl.value"
      [title]="'PREFERENCES.ADD_INSURANCE_CARD' | translate"
      [accept]="'.png,.jpeg,.jpg'"
      (fileSelected)="fileSelected($event)"
    ></files-uploader>
    <div class="buttons-wrapper">
      <div class="cancel-button" (click)="cancel()">
        <span *ngIf="displayCancelButton">
          {{ 'COMMON.ACTIONS.CANCEL' | translate }}
        </span>
      </div>

      <button mat-button class="spl-button" [disabled]="!medicalCoverageForm.valid || loading || (!dataWasChanged && !otherSettingsChanged)" (click)="save()">
        <span>{{ 'COMMON.ACTIONS.SAVE' | translate }}</span>
        <mat-spinner *ngIf="loading" color="accent" diameter="15"></mat-spinner>
      </button>
    </div>
  </ng-container>
</spl-skeleton>